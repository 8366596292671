import React, { useState } from 'react'; 
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const alertSuccess = () => {
  MySwal.fire({
    title: "Success!",
    text: "Your message has been sent!",
    icon: "success",
    showConfirmButton: true,
  });
};

const alertError = (message) => {
  MySwal.fire({
    title: "Error!",
    text: message,
    icon: "error",
    showConfirmButton: true,
  });
};

// Validation schema using Yup
const validationSchema = Yup.object({
  formName: Yup.string().required("İsminizi giriniz. / Enter your name."),
  formEmail: Yup.string().email("Geçerli email giriniz. / Enter a valid email.").required("Email adresinizi giriniz./ Enter your email"),
  message: Yup.string()
    .min(10, "En az 10 karakter olmalı. / The message should be at least 10 characters")
    .max(200, "En fazla 200 karakter olmalı. / The message should be at most 200 characters")
    .required("Mesaj giriniz. / Enter a message."),
  recaptcha: Yup.string().required("reCAPTCHA tamamlayınız. / Please complete the reCAPTCHA"),
});

function ContactForm() {

const {t} = useTranslation('services');

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = new URLSearchParams();
    payload.append("subject", "Contact Form Submission");
    payload.append("toEmail", "mukaddesagcicek@gmail.com"); // todo change it to the mimaria gmail
    payload.append("formEmail", values.formEmail);
    payload.append("formName", values.formName);
    payload.append("message", values.message);
  
    try {
      const response = await fetch("https://cms.creamycode.com/api/mail/send", {
      // const response = await fetch("http://localhost:8080/api/mail/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // Sending as URL-encoded data
        },
        body: payload.toString(), // Convert the payload to URL-encoded format
        mode: "cors",
      });
  
      if (response.ok) {
        alertSuccess();
        resetForm();
        setRecaptchaToken(null);
      } else {
        const errorMessage = await response.text();
        alertError(`Error: ${response.status} - ${errorMessage}`);
      }
    } catch (error) {
      alertError("An error occurred. Please try again.");
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };
  

  const onRecaptchaChange = (token, setFieldValue) => {
    setRecaptchaToken(token);
    setFieldValue("recaptcha", token); // Update Formik state for validation
  };

  return (
    <section className="tc-contact-form-style1">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <div className="info wow fadeInUp slow" data-wow-delay="0.2s">
              <h3 className="fsz-45 fw-500 mb-80">
                {t('let-us-build')}
              </h3>
              <p className="fsz-14 color-666 mt-15">
                {t('wont-published')} <br /> {t('required-field')}
                {t('marked')} <span style={{ color: '#FF204E' }}> * </span>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <Formik
              initialValues={{
                formName: "",
                formEmail: "",
                message: "",
                recaptcha: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form className="form mt-5 mt-lg-0 wow fadeInUp slow" data-wow-delay="0.4s">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-30">
                        <label htmlFor="formName">
                          {t('full-name')} <span className="color-orange1"> * </span>
                        </label>
                        <Field
                          type="text"
                          name="formName"
                          className="form-control"
                          placeholder="Ahmet Y."
                        />
                        <ErrorMessage
                          name="formName"
                          component="div"
                          style={{ color: '#FF204E' }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-30">
                        <label htmlFor="formEmail">
                          {t('email-address')} <span className="color-orange1"> * </span>
                        </label>
                        <Field
                          type="email"
                          name="formEmail"
                          className="form-control"
                          placeholder="ahmet@example.com"
                        />
                        <ErrorMessage
                          name="formEmail"
                          component="div"
                          style={{ color: '#FF204E' }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-30">
                        <label htmlFor="message">
                        {t('message')}<span className="color-orange1"> * </span>
                        </label>
                        <Field
                          as="textarea"
                          rows="6"
                          name="message"
                          placeholder="Merhaba Mimaria"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          style={{ color: '#FF204E' }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* Add reCAPTCHA */}
                  <ReCAPTCHA
                    sitekey="6LckDk8qAAAAAJNspeyJG1WZc5cs3BU5D6PceYTR" // Replace with your reCAPTCHA site key
                    onChange={(token) => onRecaptchaChange(token, setFieldValue)}
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="div"
                    className="text-danger mt-2"
                  />

                  <button
                    type="submit"
                    className="butn hover-bg-orange1 text-capitalize bg-white rounded mt-40"
                    disabled={isSubmitting}
                  >
                    <span>
                      {isSubmitting ? "Sending..." : "Gönder / Send"}
                      <i className="fal fa-arrow-up-right ms-2"></i>
                    </span>
                  </button>

                  <p className="fsz-12 color-666 mt-20">
                   {t('submitting')} {' '}
                    <Link to="/terms" className="color-000 text-decoration-underline">
                      {t('tandc')}
                    </Link>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <img
        src="/innerpages/assets/img/contact_shap.png"
        alt=""
        className="shap"
      />
    </section>
  );
}

export default ContactForm;
